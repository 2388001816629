import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Sector.module.scss'
import Content from 'components/Content'

const b = bem.sector(css)

const Sector = ({ title, color, children, withoutContent, isGradient, titleCentered, className, ...rest }) => {
	const Tag = title ? 'section' : 'div'
	const isDark = ['blue', 'cyan', 'green', 'orange', 'purple'].indexOf(color) !== -1
	const content = (
		<>
			{title && (
				<h2 className={b('title', { 'is-center': titleCentered })} dangerouslySetInnerHTML={{ __html: title }} />
			)}
			{children}
		</>
	)
	return (
		<Tag className={b({ color, 'is-dark': isDark, 'is-gradient': isGradient }, className)} {...rest}>
			<div className={b('inner')}>{withoutContent ? content : <Content>{content}</Content>}</div>
		</Tag>
	)
}

Sector.defaultProps = {
	color: 'white',
}

export const sectorColors = ['white', 'gray', 'blue', 'cyan', 'green', 'orange', 'purple', 'purple-lightness']
Sector.propTypes = {
	className: PropTypes.string,
	titleCentered: PropTypes.bool,
	title: PropTypes.string,
	color: PropTypes.oneOf(sectorColors),
	children: PropTypes.node.isRequired,
	withoutContent: PropTypes.bool,
	isGradient: PropTypes.bool,
}

export default Sector
